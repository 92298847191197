import React, { lazy, Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const Live = lazy(() => import("./page/live"));
const Completed = lazy(() => import("./page/completed"));
const InvalidMeeting = lazy(() => import("./page/error"));
const ZoomVideoMobile = lazy(() => import("./page/recorded-class"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Live />,
  },
  {
    path: "completed",
    element: <Completed />,
  },
  {
    path: "invalid-meeting",
    element: <InvalidMeeting />,
  },
  {
    path: "/recorded-class/:videoId/:scheduleId/:token",
    element: <ZoomVideoMobile />,
  },
]);

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
